<template>
  <div>
    <h2>{{ title }}</h2>
    <hcc-table
      :emptyRowsTable="emptyRows"
      :actualPage="paginationOptions.page"
      :rowsPerPage="paginationOptions.perPage"
      :totalPages="paginationOptions.pageTotal"
      :pagination="paginationOptions.pageTotal > 20"
      :columns="headers"
      :rows="getBlockedClients"
      @pageChanged="changePage"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'actions'">
          <hcc-button-icon
            @click="removeBlocked(props.row.id, props.index)"
          >
            <delete-icon/>
          </hcc-button-icon>
        </div>
      </template>
    </hcc-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import GetBlockedClientsGql from '@/graphql/queries/campaigns/getBlockedClients.gql';
import RemoveBlockedClientGql from '@/graphql/mutations/removeBlockedClient.gql';

export default {
  components: {
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
  },
  data: () => ({
    paginationOptions: {
      pageTotal: 0,
      page: 1,
      perPage: 25,
    },
    getBlockedClients: [],
  }),
  computed: {
    title() {
      return this.$t('campaigns.cards.blocked.title');
    },
    headers() {
      return [
        {
          label: this.$t('campaigns.cards.blocked.name'),
          field: 'name',
        },
        {
          label: this.$t('campaigns.cards.blocked.username'),
          field: 'username',
        },
        {
          label: this.$t('campaigns.cards.blocked.channel-name'),
          field: 'channelName',
        },
        {
          label: this.$t('campaigns.cards.blocked.channel-type'),
          field: 'ChannelType',
        },
        {
          label: this.$t('campaigns.cards.blocked.agent-name'),
          field: 'agentName',
        },
        {
          label: this.$t('campaigns.cards.blocked.created-at'),
          field: 'createdAt',
        },
        {
          label: this.$t('shared-components.table.actions'),
          field: 'actions',
        },
      ];
    },
    campaignId() {
      return this.$route.params.campaign_id;
    },
    emptyRows() {
      return this.paginationOptions.pageTotal === 0;
    },
  },
  apollo: {
    getBlockedClients() {
      return {
        query: gql`${GetBlockedClientsGql}`,
        variables() {
          return {
            campaign: this.campaignId,
            length: this.paginationOptions.perPage,
            page: this.paginationOptions.page,
          };
        },
        update: ({ getBlockedClients }) => {
          if (getBlockedClients && getBlockedClients.total) {
            this.paginationOptions.pageTotal = getBlockedClients.total;
          }
          return getBlockedClients.data.map(block => ({
            id: block.id,
            name: block.name,
            username: block.username,
            channelName: block.blocked.channel.name,
            ChannelType: block.blocked.channel.channelType.name,
            agentName: block.blocked.user.name,
            createdAt: block.blocked.createdAt,
          }));
        },
        skip() {
          return !this.campaignId;
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      };
    },
  },
  methods: {
    changePage(page) {
      if (this.paginationOptions.page !== page) {
        this.paginationOptions.page = page;
      }
    },
    async removeBlocked(id, index) {
      const { data } = await this.$apollo.mutate({
        mutation: RemoveBlockedClientGql,
        variables: {
          id,
          campaign: this.campaignId,
        },
      });
      if (data.removeBlockedClient.status) {
        this.getBlockedClients.splice(index, 1);
      }
    },
  },
};
</script>
